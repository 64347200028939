<template>
  <v-dialog
    v-model="modalView"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card color="CBackground ">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Daftar Jenis Pengaduan</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="modalView = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="pa-5">
        <div class="mb-5">
          <v-alert v-model="alertBerhasil" type="success" dense dismissible>
            {{ alertMassage }}
          </v-alert>
          <v-alert v-model="alertGagal" type="error" dense dismissible>
            {{ alertMassage }}
          </v-alert>
        </div>

        <v-row>
          <v-col cols="12" md="7">
            <v-data-table
              :headers="headers"
              :items="jps"
              item-key="name"
              class="elevation-1"
            >
              <template v-slot:top>
                <CModalEdit />
                <CModalDelete />
              </template>
              <template v-slot:[`item.nomor`]="{ item }">
                {{
                  jps
                    .map(function(x) {
                      return x.jp_id;
                    })
                    .indexOf(item.jp_id) + 1
                }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-1" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" md="5">
            <v-card class="py-3 px-5">
              <span class="font-weight-black">Tambah Jenis Pengaduan</span>

              <div class="mt-3">
                <v-form ref="form" class="mb-5">
                  <v-text-field
                    maxlength="50"
                    label="Nama Jenis Pengaduan"
                    autocomplete="off"
                    v-model="jp_nama"
                  ></v-text-field>

                  <v-textarea label="Keterangan" v-model="jp_ket"></v-textarea>

                  <v-divider class="my-3"></v-divider>

                  <v-btn
                    v-if="btnLoading"
                    color="primary"
                    depressed
                    block
                    @click="add()"
                    >SIMPAN</v-btn
                  >
                  <v-btn v-else block color="primary" depressed loading
                    >SIMPAN</v-btn
                  >
                </v-form>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import CModalEdit from "@/components/Admin/pengaduan/jp/modalEdit";
import CModalDelete from "@/components/Admin/pengaduan/jp/modalDelete";

import refreshView from "@/store/jenis_pengaduan/viewJP";
import modalView from "@/store/jenis_pengaduan/viewJP";
import modalEdit from "@/store/jenis_pengaduan/modalEdit";
import modalHapus from "@/store/jenis_pengaduan/modalHapus";

export default {
  components: {
    CModalEdit,
    CModalDelete,
  },

  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    session: "",
    btnLoading: true,

    jps: [],
    jp: {},

    headers: [
      {
        text: "Nomor",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "Nama Jenis Pengaduan", align: "start", value: "jp_nama" },
      { text: "Keterangan Jenis Pengaduan", align: "start", value: "jp_ket" },
      { text: "Action", value: "action", width: "100px" },
    ],

    jp_nama: "",
    jp_ket: "",
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    default() {
      this.jp_nama = "";
      this.jp_ket = "";
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "jp")
        .then((res) => {
          refreshView.commit("refreshData", false);
          this.jps = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editItem(item) {
      this.editedIndex = this.jps.indexOf(item);
      this.jp = Object.assign({}, item);
      modalEdit.commit("toggleModal", true);
      modalEdit.commit("viewModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.dleteIndex = this.jps.indexOf(item);
      this.jp = Object.assign({}, item);
      modalHapus.commit("toggleModal", true);
      modalHapus.commit("viewModal", Object.assign({}, item));
    },

    add() {
      this.btnLoading = false;

      const data = {
        jp_nama: this.jp_nama,
        jp_ket: this.jp_ket,
      };

      const url = process.env.VUE_APP_API_BASE + "jp";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.default();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.default();
        });
    },
  },
};
</script>
