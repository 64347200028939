import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        jp: {
            jp_id: 1,
            jp_nama: "",
            jp_ket: "",
            jp_created_at: ""
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },

        viewModal(state, value) {
            state.jp = value
        }
    }
})
